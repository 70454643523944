





































































































import { Component, Vue, Emit } from "vue-property-decorator";
import { hours, minutes } from "@/common/models/dates";
import { Fixture, FixturesApi, FixtureOptions } from "@/store/fixtures-api";
import { TeamsApi, Team } from "@/store/teams-api";

import { required, maxLength } from "@/common/validators/rules";
import CForm from "@/common/components/CForm.vue";

@Component({ components: { CForm } })
export default class FixtureForm extends Vue {
    fixturesStore = new FixturesApi();
    teamsStore = new TeamsApi();
    validators = { required, maxLength };
    openDialog = false;
    fixture: Fixture = {} as Fixture;
    team: Team = {} as Team;
    options: FixtureOptions = {} as FixtureOptions;

    get venues() {
        if (!this.options.venues) {
            return [];
        }
        // we can have a home venue that is also a venue for an opponent
        return this.fixture.home
            ? this.options.venues.filter((p) => p.isHome)
            : this.options.venues.filter((p) => p.opponentIds.includes(this.fixture.opponentId));
    }

    open(fixture: Fixture, teamId: number) {
        return Promise.all([
            this.teamsStore.get({ teamId: teamId }).then((res) => (this.team = res.data)),
            this.fixturesStore
                .get({ fixtureId: fixture.id })
                .then((res) => (this.fixture = res.data))
                .then(() => (this.fixture.teamId = teamId)),
            this.fixturesStore.getOptions().then(
                (res) =>
                    (this.options = {
                        ...{ hours: hours, minutes: minutes },
                        ...res.data,
                    })
            ),
        ])
            .then(() => (this.openDialog = true))
            .then(() => (this.$refs.form as CForm).resetValidation());
    }

    close() {
        this.openDialog = false;
    }

    @Emit("change")
    save() {
        if (!(this.$refs.form as CForm).validate()) {
            return;
        }

        return this.fixturesStore.save({ fixture: this.fixture }).then(() => this.close());
    }
}
