import { ValueText } from "./interfaces";

export const days: ValueText[] = [
    { value: 0, text: "Sunday" },
    { value: 1, text: "Monday" },
    { value: 2, text: "Tuesday" },
    { value: 3, text: "Wednesday" },
    { value: 4, text: "Thursday" },
    { value: 5, text: "Friday" },
    { value: 6, text: "Saturday" },
];
export const hours: ValueText[] = [
    { value: 9, text: "9am" },
    { value: 10, text: "10am" },
    { value: 11, text: "11am" },
    { value: 12, text: "12pm" },
    { value: 13, text: "1pm" },
    { value: 14, text: "2pm" },
    { value: 15, text: "3pm" },
    { value: 16, text: "4pm" },
    { value: 17, text: "5pm" },
    { value: 18, text: "6pm" },
    { value: 19, text: "7pm" },
    { value: 20, text: "8pm" },
    { value: 21, text: "9pm" },
];
export const minutes: ValueText[] = [
    { value: 0, text: "00" },
    { value: 15, text: "15" },
    { value: 30, text: "30" },
    { value: 45, text: "45" },
];
export const duration: ValueText[] = [
    { value: 30, text: "30 minutes" },
    { value: 60, text: "1 hour" },
    { value: 75, text: "1 hour 15 minutes" },
    { value: 90, text: "1.5 hours" },
    { value: 105, text: "1 hour 45 minutes" },
    { value: 120, text: "2 hours" },
];
