





























import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
import { TrainingSessionsModule, TrainingSessionSummary } from "@/store/training-sessions-module";
import TeamTrainingItem from "./TeamTrainingItem.vue";

@Component({ components: { TeamTrainingItem } })
export default class TrainingTimes extends Vue {
    profileStore = getModule(ProfileModule);
    trainingSessionsStore = getModule(TrainingSessionsModule);
    isMounted = false;
    teams: TrainingSessionSummary[] = [];

    mounted() {
        return this.getTrainingSessions().then(() => (this.isMounted = true));
    }

    getTrainingSessions() {
        return this.trainingSessionsStore.getSummary().then((res) => (this.teams = res.data));
    }
}
