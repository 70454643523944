
































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { TeamsApi, Team } from "@/store/teams-api";
import { ProfileModule } from "@/store/profile-module";
import { TrainingSessionsModule, TrainingSession } from "@/store/training-sessions-module";
import { FixturesApi, Fixture } from "@/store/fixtures-api";
import TeamForm from "./TeamForm.vue";
import TeamOfficials from "./TeamOfficials.vue";
import TeamTrainingForm from "./TeamTrainingForm.vue";
import TeamTrainingItem from "./TeamTrainingItem.vue";
import FixtureItem from "../fixtures/FixtureItem.vue";
import FixtureForm from "../fixtures/FixtureForm.vue";

@Component({
    components: {
        TeamForm,
        TeamOfficials,
        TeamTrainingForm,
        TeamTrainingItem,
        FixtureItem,
        FixtureForm,
    },
})
export default class TeamDetail extends Vue {
    profileStore = getModule(ProfileModule);
    teamsStore = new TeamsApi();
    fixtureStore = new FixturesApi();
    trainingSessionsStore = getModule(TrainingSessionsModule);
    isMounted = false;
    trainingSessions: TrainingSession[] = [];
    fixtures: Fixture[] = [];
    team: Team = {} as Team;

    get hasPermission() {
        return this.profileStore.profile.admin || this.profileStore.profile.teams?.includes(+this.$route.params.id);
    }

    mounted() {
        return this.get().then(() => (this.isMounted = true));
    }

    get() {
        return Promise.all([
            this.teamsStore.get({ teamId: +this.$route.params.id }).then((res) => (this.team = res.data)),
            this.getTrainingSessions(),
            this.getFixtures(),
        ]);
    }

    getTrainingSessions() {
        return this.trainingSessionsStore
            .getAll({ teamId: +this.$route.params.id })
            .then((res) => (this.trainingSessions = res.data));
    }

    getFixtures() {
        return this.fixtureStore.getAll({ teamId: +this.$route.params.id }).then((res) => (this.fixtures = res.data));
    }

    save() {
        if ((this.$refs.form as any).validate()) {
            return;
        }
    }

    openTeamDialog() {
        (this.$refs.teamDialog as TeamForm).open(this.team);
    }

    openTrainingSessionDialog(trainingSession: TrainingSession) {
        (this.$refs.trainingSessionDialog as TeamTrainingForm).open(trainingSession, this.team.id);
    }

    openFixtureDialog(fixture: Fixture) {
        (this.$refs.fixtureDialog as FixtureForm).open(fixture, this.team.id);
    }

    deleted() {
        this.$router.push({
            name: "teams",
            params: { type: this.$route.params.type },
        });
    }
}
