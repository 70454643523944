






















import { Component, Vue, Prop } from "vue-property-decorator";
import { Official } from "@/store/teams-api";

@Component({ components: {} })
export default class TeamOfficials extends Vue {
    @Prop() officials!: Official[];

    // todo - order by coach/manager/assts
    // todo - dedupe
    get teamOfficials() {
        var response: Official[] = [];
        this.officials.forEach((o) => {
            if (o.officialType != 2) {
                let match = response.find((p) => p.firstName == o.firstName && p.lastName == o.lastName);
                if (!match) {
                    response.push(o);
                } else {
                    match.officialTypeString += `/${o.officialTypeString}`;
                }
            }
        });
        return response;
    }

    name(official: Official) {
        return `${official.firstName} ${official.lastName}`;
    }

    source(offical: Official) {
        return offical.imageUri || "https://static.ongarchasers.com/static/no-image.jpg";
    }
}
