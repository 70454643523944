























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import CForm from "@/common/components/CForm.vue";
import { TrainingSession, TrainingSessionsModule } from "@/store/training-sessions-module";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";

@Component({ components: { CForm, CDeleteConfirm } })
export default class TeamTrainingItem extends Vue {
    @Prop({ required: true }) trainingSession!: TrainingSession;
    @Prop({ type: Boolean, default: false }) canEdit: boolean | undefined;
    trainingSessionsStore = getModule(TrainingSessionsModule);
    days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    period() {
        switch (this.trainingSession.duration) {
            case 30:
                return "30 minutes";
            case 60:
                return "1 hour";
            case 75:
                return "1 hour 15 minutes";
            case 90:
                return "1.5 hours";
            case 105:
                return "1 hour 45 minutes";
            case 120:
                return "2 hours";
            default:
                return `${this.trainingSession.duration} minutes`;
        }
    }

    day(session: TrainingSession) {
        return this.days[session.day];
    }

    time(session: TrainingSession) {
        let time = (+session.hour <= 12 ? session.hour : +session.hour - 12).toString();
        if (+session.minute > 0) {
            time += `:${session.minute.toString()}`;
        }
        return +session.hour < 12 ? `${time} AM` : `${time} PM`;
    }

    @Emit()
    edit() {
        return this.trainingSession;
    }

    @Emit("deleted")
    deleteConfirmed() {
        return this.trainingSessionsStore.delete({
            trainingSessionId: this.trainingSession.id,
        });
    }
}
