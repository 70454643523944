import { axios } from "./axios";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import store from "../store";

@Module({
    dynamic: true,
    namespaced: true,
    store,
    name: "trainingSessions",
})
export class TrainingSessionsModule extends VuexModule {
    @Action
    getAll({ teamId }: { teamId: number }) {
        return axios.get<TrainingSession[]>(`/teams/training-sessions/${teamId}`);
    }

    @Action
    getSummary() {
        return axios.get<TrainingSessionSummary[]>(`/teams/training-sessions/summary`);
    }

    @Action
    save({ trainingSession }: { trainingSession: TrainingSession }) {
        if (trainingSession.id > -1) {
            return axios.put("/teams/training-sessions", trainingSession);
        } else {
            return axios.post("/teams/training-sessions", trainingSession);
        }
    }

    @Action
    delete({ trainingSessionId }: { trainingSessionId?: number }) {
        return axios.delete(`/teams/training-sessions/${trainingSessionId}`);
    }
}

export interface TrainingSession {
    id: number;
    venueId: number;
    teamId: number;
    //frequency: number
    day: number;
    hour: number;
    minute: number;
    duration: number;
    venue: string;
}

export interface TrainingSessionSummary {
    team: string;
    teamId: number;
    category: string;
    sessions: TrainingSession[];
}
