




























import { Component, Vue, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
import { TeamsApi, Team } from "@/store/teams-api";
import TeamForm from "./TeamForm.vue";

@Component({ components: { TeamForm } })
export default class Teams extends Vue {
    @Ref() readonly teamForm!: TeamForm;
    profileStore = getModule(ProfileModule);
    teamsStore = new TeamsApi();
    teams: Team[] = [];
    loading = false;

    get isAdmin() {
        return this.profileStore.profile.admin;
    }

    mounted() {
        this.loading = true;
        this.get().then(() => (this.loading = false));
    }

    get() {
        if (this.$route.params.type == "previous") {
            return this.teamsStore.getPrevious().then((res) => (this.teams = res.data));
        } else {
            return this.teamsStore
                .getAll({ teamCategory: this.$route.params.type })
                .then((res) => (this.teams = res.data));
        }
    }

    source(team: Team) {
        return team.mediaUri || "https://static.ongarchasers.com/static/no-team.jpg";
    }

    openTeamDialog() {
        this.teamForm.open({} as Team);
    }
}
