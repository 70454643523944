

































import { Component, Vue, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { required, maxLength } from "@/common/validators/rules";
import CForm from "@/common/components/CForm.vue";
import { TeamsApi } from "@/store/teams-api";
import { TrainingSession, TrainingSessionsModule } from "@/store/training-sessions-module";
import { ValueText } from "@/common/models/interfaces";
import { days, hours, minutes, duration } from "@/common/models/dates";

@Component({ components: { CForm } })
export default class TeamTrainingForm extends Vue {
    teamsStore = new TeamsApi();
    trainingSessionsStore = getModule(TrainingSessionsModule);

    openDialog = false;
    validators = { required, maxLength };
    options = {
        days: days,
        hours: hours,
        minutes: minutes,
        duration: duration,
    };
    trainingSession: TrainingSession = {} as TrainingSession;
    venues: ValueText[] = [];

    open(trainingSession: TrainingSession, teamId: number) {
        this.teamsStore
            .getOptions()
            .then((res) => (this.venues = res.data.venues))
            .then(() => (this.openDialog = true))
            .then(() => (this.$refs.form as CForm).resetValidation());
        let session = trainingSession.id
            ? trainingSession
            : ({
                  id: -1,
                  teamId: teamId,
                  day: 0,
                  hour: 9,
                  minute: 0,
                  duration: 90,
                  venueId: 0,
                  venue: "",
              } as TrainingSession);
        this.trainingSession = { ...session };
    }

    close() {
        this.openDialog = false;
    }

    @Emit("change")
    save() {
        if (!(this.$refs.form as CForm).validate()) {
            return;
        }

        return this.trainingSessionsStore.save({ trainingSession: this.trainingSession }).then(() => this.close());
    }

    @Emit("change")
    remove() {
        return this.trainingSessionsStore
            .delete({ trainingSessionId: this.trainingSession.id })
            .then(() => this.close());
    }
}
