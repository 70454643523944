
























































































import { Component, Vue, Emit } from "vue-property-decorator";
import { TeamsApi, Team, TeamOptions } from "@/store/teams-api";
import { required, maxLength } from "@/common/validators/rules";
import CForm from "@/common/components/CForm.vue";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";

@Component({ components: { CForm, CDeleteConfirm } })
export default class TeamForm extends Vue {
    teamsStore = new TeamsApi();
    validators = { required, maxLength };
    openDialog = false;
    team: Team = {} as Team;
    options!: TeamOptions;

    open(team: Team) {
        this.team = team;
        return this.teamsStore
            .getOptions()
            .then((res) => (this.options = res.data))
            .then(() => {
                if (!this.team.teamCategoryId) {
                    this.team.teamCategoryId = this.options.teamCategories.find(
                        (p) => p.text.toLowerCase() == this.$route.params.type
                    )?.value;
                }
            })
            .then(() => (this.openDialog = true))
            .then(() => (this.$refs.form as CForm).resetValidation());
    }

    close() {
        this.openDialog = false;
    }

    @Emit("change")
    save() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        return this.teamsStore.save({ team: this.team }).then(() => this.close());
    }

    confirmDelete() {
        (this.$refs.delete as CDeleteConfirm).open(this.team);
    }

    @Emit("deleted")
    deleteConfirmed() {
        return this.teamsStore.delete({ teamId: this.team.id }).then(() => this.close());
    }
}
